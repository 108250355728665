<template>
  <div
    :id="uid"
    class="v-address-select-map-container v-mb-sm"
    :class="{ disabled: disabled }"
  />
  <template v-if="loaded">
    <Teleport :to="`#delivery-point-${uid}`">
      <slot />
    </Teleport>
  </template>
</template>

<script setup lang="ts">
import type { AddressCoordinates } from '~types/clientStore'
import type { MapType } from '~types/mapsStore'

import type { YMapFeature } from '@yandex/ymaps3-types'
import type { DomEvent, DomEventHandlerObject } from '@yandex/ymaps3-types/imperative/YMapListener'

const { coords, type, clickCallback } = defineProps<{
  coords?: AddressCoordinates | undefined
  type: MapType
  disabled?: boolean
  clickCallback: (object: DomEventHandlerObject | undefined, event: DomEvent) => Promise<void>
}>()

const addressStore = useAddressStore()
const clientStore = useClientStore()
const mapsStore = useMapsStore()

const appConfig = useAppConfig()

const loaded = ref<boolean>(false)

onMounted(async () => {
  await addressStore.initCities()

  if (ymaps3Loaded.value) {
    await makeMap()
  } else {
    await mapsStore.initYandexMaps()
  }
})

onUnmounted(() => {
  loaded.value = false
  mapsStore.unmakeYMap(uid)
})

const ymaps3Loaded = computed<boolean>(
  () => mapsStore.YandexMaps.state === 'success' && addressStore.CityOrganisation?.data !== null
)

watch(ymaps3Loaded, async (newValue, oldValue) => {
  if (newValue !== oldValue) {
    await makeMap()
  }
})

async function makeMap(): Promise<void> {
  const polygons: YMapFeature[] = []
  let totalCoords: number[][][] = [[[]]]
  let color = 'rgba(0,0,0,0)'
  for (const city of addressStore.ActiveCities) {
    //for each city
    for (const district of city.DistrictsPrices) {
      //for each district
      if (
        !district.PolygonConfiguration ||
        !district.PolygonConfiguration.Coordinates ||
        district.PolygonConfiguration.Coordinates.length === 0 ||
        district.PolygonConfiguration.Coordinates[0].length === 0 ||
        !district.Departments ||
        district.Departments.length === 0
      )
        continue

      // Технически у района может быть несколько отделов, а у отделов может быть несколько терминалов.
      // Реально такая настройка для типа "доставка" является некорректной. В данном случае будет в консоль отображаться ошибка
      // А для оценки "серой зоны" будет оцениваться наличие хотя бы одного терминала, который не работает по расписанию
      if (district.Departments.length > 1) {
        console.error('There are more then one department links with district %s', district.ID)
      }

      let anyTerminalDontWorkingOnScheduleNow = false
      let haveUnavailableTerminals = false // Если у района хотя бы один их терминалов временно недоступен или временно не доступен на доставку

      for (const department of district.Departments) {
        if (department.Terminals.length > 1) {
          console.error('There are more then one terminal links with department %s', department.ID)
        }
        for (const terminal of department.Terminals) {
          if (terminal.NotWorkingOnScheduleNow) {
            anyTerminalDontWorkingOnScheduleNow = true
          }
          if (
            terminal.Active &&
            (terminal.IsTemporarilyNonActive || terminal.TemporarilyUnavailableForDelivery)
          ) {
            haveUnavailableTerminals = true
          }
        }
      }
      if (!(district.HideIfTerminalIsInactive && haveUnavailableTerminals)) {
        totalCoords = [...totalCoords, ...district.PolygonConfiguration.Coordinates]
        // случай с HideIfTerminalIsInactive обработан на сервере (кроме haveUnavailableTerminals)
        if (haveUnavailableTerminals || anyTerminalDontWorkingOnScheduleNow) {
          polygons.push(
            new ymaps3.YMapFeature({
              id: district.ID,
              geometry: {
                type: 'Polygon',
                coordinates: mapsStore.makeYMapPolygonCoords(district)
              },
              style: {
                fill: '#858485',
                fillOpacity: Number.parseFloat(appConfig.VueSettingsPreRun.YaMapsPolygonOpacity),
                stroke: [
                  {
                    color: '#454545',
                    width: 1
                  }
                ]
              }
            })
          )
        } else {
          if (district.ID === clientStore.ClientState.data?.SelectedDeliveryAddress?.DistrictID) {
            color = district.PolygonConfiguration.FillColor
          }

          polygons.push(
            new ymaps3.YMapFeature({
              id: district.ID,
              geometry: {
                type: 'Polygon',
                coordinates: mapsStore.makeYMapPolygonCoords(district)
              },
              properties: {
                district: district
              },
              style: {
                fill: district.PolygonConfiguration.FillColor,
                fillOpacity: Number.parseFloat(appConfig.VueSettingsPreRun.YaMapsPolygonOpacity),
                stroke: [
                  {
                    color: district.PolygonConfiguration.StrokeColor,
                    width: 1
                  }
                ]
              }
            })
          )
        }
      }
    }
  }

  const bounds = mapsStore.makeYMapBounds(totalCoords)
  await mapsStore.makeYMap(uid, type, bounds, clickCallback)
  mapsStore.saveYMapBounds(bounds)

  mapsStore.addYMapsFeatures(uid, polygons)

  const longitude = coords?.Longitude ?? (bounds.maxLongitude + bounds.minLongitude) / 2
  const latitude = coords?.Latitude ?? (bounds.maxLatitude + bounds.minLatitude) / 2

  mapsStore.addDeliveryPoint(uid, color, coords?.Longitude ?? 0, coords?.Latitude ?? 0)
  mapsStore.changeYMapCenter(uid, [longitude, latitude])
  loaded.value = true
}

const uid = `map-polygon-${useId()}`
</script>

<style lang="scss">
@use '~/assets/variables';

.v-address-select-map-container {
  height: 500px;
  box-shadow: variables.$InputShadow;
  border-radius: variables.$BorderRadius;
  overflow: hidden;
}
</style>
